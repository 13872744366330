import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
} from 'reactstrap';

const LogoutButton = () => {
  const { logout, user } = useAuth0();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const redirectUrl = process.env.REACT_APP_FULL_URL;

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const base = `${process.env.REACT_APP_BASE_URL}/mywm${process.env.REACT_APP_URL_ENV}/`;

  return (
    <Dropdown nav direction="start" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav className="no-underline" style={{ fontSize: 'large' }}>
        <span style={{ color: 'white' }}>
          {`Hello, ${user.name?.split(' ')[0]}`}
        </span>
        <br />
        <span className="mywm__account_link">My Account</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem href={`${base}account/settings`}>Account Settings</DropdownItem>
        <DropdownItem href={`${base}dashboard`}>Dashboard</DropdownItem>
        <DropdownItem href={`${base}orders`}>Orders</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => logout({ logoutParams: { returnTo: redirectUrl } })}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LogoutButton;
