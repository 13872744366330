import React from 'react';
import { Col, Row } from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Auth0Form, Attachments, CrudForm, HelperFunctions, HtmlHelper, Notes, TimeSlots,
} from '@westernmilling/react_component_library';

import GradeFactors from 'components/Forms/Order/GradeFactors';
import Summary from 'components/Forms/Order/Summary';

export default withAuth0(class OrderAuthorized extends Auth0Form {
  formType = () => 'Order';

  farmDisplay = () => (
    this.getItem().farm_or_delayed ? (
      <>
        {this.getRow('farm', 'Farm')}
        {this.getRow('field', 'Field')}
        {this.getRow('field_tag', 'Field Tag')}
      </>
    ) : ''
  );

  shipInfo = () => (
    <>
      {HtmlHelper.divider()}
      <h4>
        Shipping Details
      </h4>
      <br />
      <Row>
        <Col lg={4} md={12}>
          {this.getRowWithValue('Pickup Location', <pre>{this.getItem().source_location}</pre>)}
          {this.farmDisplay()}
        </Col>
        <Col lg={4} md={12}>
          {this.getRow('release__release_number', 'Release')}
        </Col>
        <Col lg={4} md={12}>
          {HelperFunctions.isEmpty(this.getItem().appointment) ? this.requestedDate() : this.appointmentDate()}
        </Col>
      </Row>
    </>
  );

  requestedDate = () => {
    const timeSlot = TimeSlots.GetTimeSlotName(this.getItem().time_slot, this.getItem().requested_time_slot_time);
    return this.getRowWithValue('Requested Date', `${this.getItem().requested_delivery_date} ${timeSlot}`);
  };

  appointmentDate = () => this.getRow('appointment', 'Appointment');

  manufactureDetails = () => (
    this.getItem().show_manufacturing ? (
      <div>
        {HtmlHelper.divider()}
        <h4>
          Manufacturing Details
        </h4>
        <br />
        <Row>
          <Col lg={4} md={12}>
            {this.getRow('manufacturing_started_at', 'Started At')}
          </Col>
          <Col lg={4} md={12}>
            {this.getRow('manufacturing_completed_at', 'Completed At')}
          </Col>
        </Row>
      </div>
    ) : ''
  );

  gradeFactors = () => (
    <GradeFactors
      authState={this.getAuth()}
      orderUuid={this.getItem().uuid}
      itemUuid={this.getItem().item_uuid}
      showToggle
    />
  );

  attachments = () => (
    <Attachments
      authState={this.getAuth()}
      item={this.getItem()}
      modelType="Order"
      attachmentsShowing
      isAddAdmin
      hideBottomHr
      hideInternal
      showCategory
      section="orders"
      category="orders"
      categoryType="MyWM"
      closeOnZero
      showInternal={false}
    />
  );

  modifyData = () => this.getItem().notes_on_order;

  tableNote = () => (
    <div>
      ATTENTION: Please note that Western Milling does not actively monitor notes; they are intended for reference only.
      For any questions regarding your order, please use the
      {' '}
      <a className="underline-link" href={`${process.env.REACT_APP_FULL_URL}/contact_us`} target="_blank" rel="noreferrer">
        Contact Us
      </a>
      {' '}
      form.
    </div>
  );

  modalNote = () => (
    <div>
      {this.tableNote()}
      <br />
    </div>
  );

  notes = () => (
    <Notes
      authState={this.getAuth()}
      item={this.getItem()}
      modelType="Order"
      notesShowing
      isAddAdmin
      hideBottomHr
      hideInternal
      modifyData={this.modifyData}
      closeOnZero
      showInternal={false}
      modalNote={this.modalNote()}
      tableNote={this.tableNote()}
    />
  );

  auditLogs = () => '';

  modalBodyRows = () => (
    <div>
      <Summary item={this.getItem()} authorized />
      {this.shipInfo()}
      {this.manufactureDetails()}
      <hr />
      {this.gradeFactors()}
      <br />
      {this.notes()}
      <br />
      {this.attachments()}
      <br />
    </div>
  );
});
