import React from 'react';
import { Button } from 'reactstrap';
import { ColumnButton, ColumnFields, HelperFunctions } from '@westernmilling/react_component_library';

const Columns = (context) => [
  {
    Header: 'Grade Factor',
    accessor: 'name_with_agris_code',
    className: 'text-start',
  },
  {
    Header: 'Inbound Value',
    accessor: 'inbound_value',
    className: 'text-end',
  },
  {
    Header: 'Outbound Value',
    accessor: 'outbound_value',
    className: 'text-end',
  },
];

export default Columns;
