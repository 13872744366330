import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { Col, Collapse, Row } from 'reactstrap';
import { CaretToggle, Auth0ListContainer } from '@westernmilling/react_component_library';

import Columns from 'helpers/columns/OrderGradeFactors';

export default withAuth0(class OrderGradeFactors extends Auth0ListContainer {
  getListApi = () => 'order_grade_factors';

  getListParams = () => (
    {
      query: `item_uuid = '${this.props.itemUuid}' and order_uuid = '${this.props.orderUuid}'`,
      order: 'sort_order',
    }
  );

  getColumns = () => Columns(this);

  isAddAdmin = () => false;

  getStorageName = () => 'otto_order_grade_factors_list';

  getPageTitle = () => 'Grade Factors';

  getTableClassSettings = () => 'wm_short_table';

  contentDisplay = () => this.getInnerTableContent();
});
